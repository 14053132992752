import React, { Component } from "react";
import Button from "../../../components/Nifty/UI/Button/Button";
import classes from "./Login.css";
import Layout from "../../../components/Auth/Layout";
import { Link } from "react-router-dom";
import { withFormik, Field } from "formik";
import { connect } from "react-redux";
import actions from "../../../store/actions/auth.actions";
import Recaptcha from 'react-recaptcha';
import IconButton from "@material-ui/core/IconButton";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

class LoginContainer extends Component {

  constructor(propse) {
    super(propse)
    this.verifyLogin();
    this.captureR = this.captureR.bind(this);
    this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
    this.expiredCallback = this.expiredCallback.bind(this);
    this.verifyCallback = this.verifyCallback.bind(this);
    this.state = {
      isVerified: false,
      password: "",
      showPassword: false,
      state: true,
    }
    this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
    this.handlePasswordChange = this.handlePasswordChange.bind(this);
    this.props.values.state = false;
  };

//valida caracteres especiales en el campo de username 
validarCampo = (e) => {
  const inputValue = e.target.value;
  const regex = /^[a-zA-Z0-9]*$/; // Expresión regular que permite letras mayúsculas, letras minúsculas, números y cadenas vacías
  if (!regex.test(inputValue)) {
    this.props.setFieldError('username', 'El campo no debe contener caracteres especiales');
  } else {
    this.props.setFieldError('username', ''); // Eliminar el error si el valor es válido
    // Actualiza el valor de entrada del campo 'username' sólo si es válido
    this.props.setFieldValue('username', inputValue, true);
  }
}




  handleClickShowPassword = () => {
    //this.state.showPassword= !this.state.showPassword;
    this.setState({ showPassword: !this.state.showPassword });
  };

  handlePasswordChange = (event) => {
    //this.state.password = event.target.value ;//debugger;
    this.setState({ password: event.target.value });
  };

  captureR() {
    if (this.state.isVerified) {
      //console.log('funciona recaptcha');
    } else {
      //console.log('NO funciona recaptcha');
      //throw new Error('This is not an error. This is just to abort javascript');
    }
  }

  recaptchaLoaded() {
    //console.log('captcha loaded correctly');
  }

  expiredCallback() {
    this.setState({
      isVerified: false,
      state: true
    })
    this.props.values.state = false;
    //console.log('expired  captcha');
  }
  verifyCallback(response) {

    if (response) {
      this.setState({
        isVerified: true,
        state: false
      })
      this.props.values.state = true;
    }
  }

  verifyLogin() {
    //console.log(localStorage.getItem);
    if (localStorage.getItem("sesiones") && typeof localStorage.getItem("sesiones") !== 'undefined' && localStorage.getItem("sesiones") != null) {
      window.location = "/";
    }
  }

  render() {
    return (
      <Layout>
        {this.props.loginError ? <div className="alert alert-danger">{this.props.loginError}</div> : null}


        <div className="panel-body" style={{ padding: 'auto' }}>
          <div
            className={["mar-ver", "pad-btm", classes.TitleSection].join(" ")}
          >
            <h1 className={["mar-ver1", "pad-btm", classes.TitleSection1].join(" ")}>Iniciar sesión</h1>
            <p>Iniciar sesión en tu cuenta de SET-ICAP | FX</p>
          </div>
          <form onSubmit={this.props.handleSubmit}>
            <div
              className={[
                "form-group",
                this.props.errors.username && this.props.touched.username ? "has-error" : ""
              ].join(" ")}

            >
              <Field
                style={{ width: '80%', margin: 'auto' }}
                name="username"
                placeholder="Usuario"
                className="form-control"
                maxLength="50"
                onChange={this.validarCampo} // Agrega esto
              />
              {this.props.errors.username &&
                this.props.touched.username && (
                  <div style={{ width: '74%', margin: 'auto', marginTop: '6px' }} className="help-block">{this.props.errors.username}</div>
                )}
            </div>

            
            <div
              className={[
                "input-group",
                this.props.errors.password && this.props.touched.password ? "has-error" : ""
              ].join(" ")}
            >
              <Field
                style={{ width: '80%', marginLeft: '10%' }}
                type={this.state.showPassword ? "text" : "password"}
                /*onChange={this.handlePasswordChange}
                value={this.state.password}*/
                name="password"
                id="password"
                placeholder="Contraseña"
                className="form-control"
                maxLength="50"
              />
              <IconButton style={{ margin: '-3px 0 0 -40px', zIndex: '3' }}
                onClick={this.handleClickShowPassword}
                onMouseDown={this.handleMouseDownPassword}
              >
                {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>


              {this.props.errors.password &&
                this.props.touched.password && (
                  <div style={{ width: '74%', margin: 'auto', marginTop: '6px' }} className="help-block">{this.props.errors.password}</div>
                )}
            </div>
            <div className="checkbox pad-btm text-left" style={{ width: '80%', margin: 'auto', marginTop: '20px' }}>
              <label >
                <Field name="rememberme" type="checkbox" />
                Recuérdame
              </label>
            </div>

            {/* LA - Oculta el RECAPTCHA */}
             {/*
            <div style={{ width: '75%', margin: 'auto', overflow: 'hidden' }}>
              <Recaptcha
                sitekey="6Ldu0rgZAAAAAM0yru80ZJLhNZBy_y5jGwz_qdC9"
                render="explicit"
                verifyCallback={this.verifyCallback}
                onloadCallback={this.recaptchaLoaded}
                expiredCallback={this.expiredCallback}
              />
            </div>

            <Button style={{ width: '80%', margin: 'auto', marginTop: '20px' }} type="submit" onClick={this.captureR} disabled={this.state.state}>Iniciar sesión</Button>
            */}
            
            {/* LA- para mostrar el boton de inicio siempre habilitado, sin recpatcha */}
            
            <Button style={{ width: '80%', margin: 'auto', marginTop: '20px' }} type="submit">Iniciar sesión</Button>
           
            <br></br>

          </form>
        </div>

        <div className="pad-all">
          <Link to="/auth/recuperar-contrasena/" className="btn-link pull-left">
            ¿Olvidaste tu contraseña?
          </Link>
          <Link to="/auth/registro/" className="btn-link pull-right">
            Crear nueva cuenta
          </Link>
        </div>
      </Layout>
    );
  }
}
var state_;
const mapPropsToValues = props => {

  return {
    username: props.username || "",
    password: props.password || "",
    rememberme: props.rememberme || "",
    state: props.state || "",
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password, history, state) => dispatch(actions.login(username, password, history, state))
  };
};

const mapStateToProps = state => ({
  loginError: state.auth.error
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  withFormik({
    mapPropsToValues: mapPropsToValues,
    validate: values => {//debugger;
      const errors = {};
      if (!values.username) {
        errors.username = "El usuario es requerido";
      }
      if (!values.password) {
        errors.password = "Por favor digite una contraseña";
      }
      return errors;
    },
    handleSubmit(values, cmp) {
      /* LA inhabilita capthca
      if (document.readyState == "loaded" || document.readyState == "interactive" || document.readyState == "complete") {//debugger;
        if (!values.state) {
          //console.log('no ah dado click al reCAPTCHA');
          return false;
        }
      }
      */
      cmp.props.login(values.username, values.password, cmp.props.history);
    }
  })(LoginContainer)
);
